const SELECTOR_CARD_DECK = '.card-deck.card-deck-slider';
const SELECTOR_CONTAINER = '.card-deck__cards';
const SELECTOR_NEXT_BUTTON = '.card-deck__next';
const SELECTOR_PREV_BUTTON = '.card-deck__prev';
const SELECTOR_SLIDE = '.card-deck__card';
const SELECTOR_SLIDE_STATUS = '.card-deck__slide-status';

$(function () {
	'use strict';

	function CardDeck(element) {
		this._element = element;
		this._container = $(element).find(SELECTOR_CONTAINER);
		this._nextButton = $(element).find(SELECTOR_NEXT_BUTTON);
		this._prevButton = $(element).find(SELECTOR_PREV_BUTTON);
		this._activeSlide = 0;
		this._oldSlide = 0;
		this._slides = $(element).find(SELECTOR_SLIDE);
		this._offsets = [];
		this._slidesToShow = 2;
		this._maxSlides = Math.ceil(this._slides.length / this._slidesToShow);
		this._duration = 0.5;

		this._addEventListeners();
		this._resize();
	}

	// Shorthand helper
	const _proto = CardDeck.prototype;

	_proto._nextSlide = function _nextSlide(event) {
		event.preventDefault();
		this._oldSlide = this._activeSlide;
		this._activeSlide += 1;
		this._slideAnim();
	};

	_proto._prevSlide = function _prevSlide(event) {
		event.preventDefault();
		this._oldSlide = this._activeSlide;
		this._activeSlide -= 1;
		this._slideAnim();
	};

	// Main slide animation
	_proto._slideAnim = function _slideAnim(event) {
		if (event instanceof Draggable) {
			this._activeSlide = this._offsets.indexOf(event.endX);
		}

		if (event !== undefined) {
			this._oldSlide = this._activeSlide;
		}

		this._stayInBounds();

		if (this._oldSlide === this._activeSlide) {
			return;
		}
		// if we're dragging we don't animate the container
		// TODO
		if (!(event instanceof Draggable)) {
			gsap.to(this._container, this._duration, {
				x: this._offsets[this._activeSlide],
			});
		}

		this._updateControls();
	};

	_proto._resize = function _resize() {
		this._offsets = [];
		const slideWidth = this._slides[0].offsetWidth;
		this._slidesToShow = Math.ceil(this._element.offsetWidth / slideWidth);
		this._maxSlides = Math.ceil(this._slides.length / this._slidesToShow);

		for (let i = 0; i < this._maxSlides; i++) {
			this._offsets.push(-slideWidth * this._slidesToShow * i);
		}

		this._stayInBounds();

		gsap.set(this._container, { x: this._offsets[this._activeSlide] });
		this._drag[0].vars.snap = this._offsets;
		this._updateControls();
	};

	_proto._addEventListeners = function _addEventListeners() {
		const _this = this;
		$(this._nextButton).on('click', function (event) {
			_this._slideAnim(event);
			return _this._nextSlide(event);
		});
		$(this._prevButton).on('click', function (event) {
			return _this._prevSlide(event);
		});
		$(window).on('resize', function (event) {
			return _this._resize(event);
		});

		_this._drag = Draggable.create(_this._container, {
			type: 'x',
			edgeResistance: 1,
			snap: _this._offsets,
			inertia: true,
			onDragEnd() {
				_this._slideAnim(this);
				_this._updateControls();
			},
			allowNativeTouchScrolling: false,
			zIndexBoost: false,
		});
	};

	// Helper to make sure we're not past the end or beginning slide
	_proto._stayInBounds = function _stayInBounds() {
		this._activeSlide = this._activeSlide < 0 ? 0 : this._activeSlide;
		this._activeSlide =
			this._activeSlide > this._maxSlides - 1
				? this._maxSlides - 1
				: this._activeSlide;
	};

	_proto._updateControls = function _updateControls() {
		$(this._element)
			.find(SELECTOR_SLIDE_STATUS)
			.html(`${this._activeSlide + 1}/${this._maxSlides}`);
	};

	// Define jQuery plugin
	$.fn.cardDeck = function () {
		const opt = arguments[0];
		const args = Array.prototype.slice.call(arguments, 1);
		let ret;
		for (let i = 0; i < this.length; i++) {
			if (typeof opt === 'object' || typeof opt === 'undefined') {
				this[i].cardDeck = new CardDeck(this[i], opt);
			} else {
				ret = this[i].cardDeck[opt].apply(this[i].cardDeck, args);
			}

			if (typeof ret !== 'undefined') return ret;
		}
		return this;
	};

	// Initialize the elements on the page
	const targets = document.querySelectorAll(SELECTOR_CARD_DECK);
	targets.forEach((el) => {
		$(el).cardDeck();
	});
});
