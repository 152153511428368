// Bootstrap Imports
import 'popper.js';
// import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/dropdown';
import './animations';

/*******************************************
 *  Helper Functions
 *******************************************/

// Handles flash of unstyled content.
function fouc() {
	$('html').removeClass('no-js').addClass('js');
}

// Add all scroll functions here
function scroll() {
	mainNavigationScroll();
}

// Optimize the scroll events.
function optimizeScroll() {
	let scrollWaiting = false,
		endScrollHandle;
	$(window).scroll(function () {
		if (scrollWaiting) {
			return;
		}
		scrollWaiting = true;

		// clear previous scheduled endScrollHandle
		clearTimeout(endScrollHandle);

		scroll();

		setTimeout(function () {
			scrollWaiting = false;
		}, 100);

		// schedule an extra execution of scroll() after 200ms
		// in case the scrolling stops in next 100ms
		endScrollHandle = setTimeout(function () {
			scroll();
		}, 200);
	});
}

function mainNavigationScroll() {
	const scrollTop = $(window).scrollTop();
	const $siteHeader = $('.site-header');
	const $siteHeaderSpacer = $('.site-header__spacer');

	if (scrollTop > 0) {
		$siteHeader.removeClass('top');
		$siteHeaderSpacer.removeClass('top');
	} else {
		$siteHeader.addClass('top');
		$siteHeaderSpacer.addClass('top');
	}
}

function mainNavigation() {
	const $navbar = $('.navbar-nav');
	const $menuBanner = $('.menu-banner');

	// Load the scroll state
	mainNavigationScroll();

	$(window).on('load resize', function () {
		$('#mobile-menu-collapse').collapse('hide');
	});

	$('#main-menu-container').on('shown.bs.collapse', function () {
		const droprights = $('.dropright');
		droprights.each(function () {
			const $this = $(this);
			const offset = $navbar.offset().top - $this.offset().top;
			const $menu = $this.children('.dropdown-menu').eq(0);

			$($menu).css('transform', `translate3d(0px, ${offset}px, 0px)`);
		});
	});

	$('.navbar-nav .dropright').on('show.bs.dropdown', function () {
		$navbar.addClass('open');
	});
	$('.navbar-nav .dropright').on('hide.bs.dropdown', function () {
		$navbar.removeClass('open');
	});

	// Switch menu image on hover and focus.
	const switchMenuImage = function (menuItem) {
		let $nextActive = '';
		let nextActiveTitle = '';

		if (typeof menuItem === 'undefined') {
			$nextActive = $menuBanner.find('.menu-item-current');
			nextActiveTitle = $menuBanner.attr('data-current');
		} else {
			$nextActive = $menuBanner.find(`.${menuItem.id}`);
			nextActiveTitle = $navbar.find(`.${menuItem.id} a`).attr('title');
		}

		// Disable currently active image
		$('.menu-banner-image .active').removeClass('active');
		$nextActive.addClass('active');

		// Update title
		gsap.timeline()
			.to('.menu-page-title-heading', { duration: 0.25, x: '-100%' })
			.set('.menu-page-title-heading', {
				text() {
					return nextActiveTitle;
				},
			})
			.to('.menu-page-title-heading', { duration: 0.25, x: 0 });
	};

	$('.navbar-nav .nav-item').hover(
		function () {
			// Hover enter
			switchMenuImage(this);
		},
		function () {
			// Hover leave
			switchMenuImage();
		}
	);

	$('.navbar-nav .nav-item a').focus(function () {
		switchMenuImage(this.parentElement);
	});
}

/*******************************************
 *  Custom Functions for the theme
 *******************************************/

function resourcesBlockAjax() {
	const getResources = function () {
		// Prepare the data to be sent to the server.
		// The action describes the function callback
		// The nonce is used to verify that the request is secure
		const ajaxData = {
			action: 'dcj_get_resources',
			nonce: dcjlocal.nonce,
			s: $('#resources-search').val(),
			dropdown_ids: $('#resources-dropdown :selected').attr(
				'data-resources'
			),
		};

		// Send the ajax post to the server and wait for a response.
		$.ajax({
			type: 'POST',
			url: dcjlocal.ajaxurl,
			data: ajaxData,
			success(response) {
				// Successful post, replace html with response.
				const html = $($.parseHTML(response));
				$('#resources-results').html(html);
			},
		});
	};

	$('#resources-search-form').on('submit', function (e) {
		e.preventDefault();
		getResources();
	});

	$('#resources-dropdown').on('change', function () {
		getResources();
	});
}

function OnMainNavOpen() {
	$('.navbar-toggler').on('click', function () {
		$('body').toggleClass('modal-open'); //reused bootstrap modal-open class to disable scroll when menu is open
	});
}

/**
 * On Loads
 */
$(document).ready(function () {
	fouc();
	optimizeScroll();
	mainNavigation();
	OnMainNavOpen();
	resourcesBlockAjax();
});
